<div class="main-container" *ngIf="!isIE() && !isSamsungBrowser()" [class.load-min-height-activation]="!loadMinHeight">
    <div class="loading" [hidden]="!appState.loading"></div>
    <app-header-main-navbar [pageUrl]="pageUrl"></app-header-main-navbar>
    <div class="page-container" id="page-container" [class.new-paddings]="!!maintenanceExists" [class.load-min-height-activation]="!loadMinHeight" [style.paddingTop.px]="alertBannerHeight">
        <router-outlet></router-outlet>
    </div>
    <app-footer-main-navbar></app-footer-main-navbar>
</div>
<div *ngIf="isIE() || isSamsungBrowser()" class="ie__message">
    <img class="ie__logo" src="/assets/img/logo.png"
         alt="Qué tal Mobile logo">
    <br>
    <br>
    <p class="ie__text">Our apologies, but we do not support your current browser,
      <br>Please use FireFox or Google Chrome to access our site</p>
    <div class="ie__links">
    <a href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=en-GB">
      <img class="ie__icons" src="/assets/img/chrome.jpg" alt="chrome"/>
    </a>
    <a href="https://www.mozilla.org/en-US/firefox/new/">
      <img class="ie__icons" src="/assets/img/firefox.jpg" alt="firefox"/>
    </a>
    </div>
</div>